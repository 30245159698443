/* tslint:disable object-shorthand-properties-first */

import { BlogPosting, WithContext } from 'schema-dts'
import { PostContent } from 'components/pages/blog/Post/Post'
import WebsiteSchema from 'shared/util/schema/WebsiteSchema'
import { getOrganization } from 'shared/util/schema/organizationUtils'

const getBlogPosting = ({
    frontmatter: {
      title,
      image: {
        childImageSharp: {
          fluid: { src },
        },
      },
      dateRaw,
      description,
      name,
      keywords,
      author,
    },
  }: PostContent,
  schema: WebsiteSchema,
): WithContext<BlogPosting> => ({
  '@context': 'https://schema.org',
  '@type': 'BlogPosting',
  author: {
    '@type': 'Person',
    name: author,
  },
  publisher: getOrganization(schema),
  url: `${process.env.GATSBY_WEBSITE_URL}${name}`,
  headline: title,
  datePublished: dateRaw,
  inLanguage: 'en-US',
  mainEntityOfPage: 'True',
  image: src,
  description,
  keywords,
})

export default getBlogPosting
