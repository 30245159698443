import * as React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'gatsby'
// tslint:disable-next-line:import-name
import Img from 'gatsby-image'

import WebsiteConfig from 'WebsiteConfig'

import getBlogPosting from 'shared/util/schema/getBlogPosting'

import Seo from 'components/shared/Seo/Seo'
import PageSection from 'components/shared/PageSection/PageSection'
import JsonLd from 'components/scripts/JsonLd/JsonLd'
import PageHeader from 'components/shared/PageHeader/PageHeader'
import PageFooter from 'components/shared/PageFooter/PageFooter'

import './Post.scss'
import getWebPageCombined from 'shared/util/schema/getWebPageCombined'

export type PostPageProps = {
  websiteConfig: WebsiteConfig
  location: {
    pathname: string
  }
  data: {
    site: {
      siteMetadata: any
    }
    markdownRemark: PostContent
    previous?: PostNavLink
    next?: PostNavLink
  }
}

export type PostContent = {
  html: string
  frontmatter: {
    slug: string
    title: string
    name: string
    description: string
    date: string
    dateRaw: string
    image: {
      childImageSharp: ChildImageSharp
    }
    author: string
    keywords: string
    excerpt?: string
  }
}

type PostNavLink = {
  frontmatter: {
    title: string
    slug: string
  }
}

export class Post extends React.Component<PostPageProps> {
  render(): React.ReactNode {
    const { markdownRemark: post, previous, next } = this.props.data
    const image = post.frontmatter.image.childImageSharp.fluid

    const {
      menuItems,
      footer,
      schema,
    } = this.props.websiteConfig

    return (
      <>
        <Seo
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.frontmatter.excerpt}
          article={true}
          image={image.src}
          websiteConfig={this.props.websiteConfig}
        />
        <JsonLd>{getWebPageCombined(schema)}</JsonLd>
        <JsonLd>{getBlogPosting(post, schema)}</JsonLd>
        <PageHeader
          pathname={this.props.location.pathname}
          items={menuItems}
          activeItem="#blog"
          rewriteActive="/blog"
          texts={{
            ...this.props.data.site.siteMetadata,
          }}
        />
        <PageSection id={post.frontmatter.name} className="Post d-md-flex">
          <Container>
            <article
              className="
                Post__container
                d-flex flex-column
                align-items-center
                justify-content-center
                u-full-width
                text-left
              "
            >
              <header className="mb-4">
                <h1 className="Post__title page-title mb-3 mt-lg-4">{post.frontmatter.title}</h1>
                <time
                  className="Post__date"
                  dateTime={post.frontmatter.date}
                >
                  {post.frontmatter.date}
                </time>
              </header>
              <figure className="Post__imageContainer">
                <Img
                  fluid={image}
                  className="Post__image"
                  alt={post.frontmatter.title}
                />
              </figure>

              <div
                className="Post__body"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />

              <nav className="Post__navLinks">
                <ul
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    listStyle: 'none',
                    padding: 0,
                  }}
                >
                  <li>
                    {previous && (
                      <Link to={previous.frontmatter.slug} rel="prev">
                        ← {previous.frontmatter.title}
                      </Link>
                    )}
                  </li>
                  <li>
                    {next && (
                      <Link to={next.frontmatter.slug} rel="next">
                        {next.frontmatter.title} →
                      </Link>
                    )}
                  </li>
                </ul>
              </nav>

            </article>
          </Container>
        </PageSection>
        <PageFooter {...footer}/>
      </>
    )
  }
}

export default Post
