import * as React from 'react'
import { Helmet } from 'react-helmet'
import WebsiteConfig from 'WebsiteConfig'

type SeoProps = {
  description: string
  title: string
  websiteConfig: WebsiteConfig
  article?: boolean
  image?: string
  url?: string
}

const Seo = ({ title, description, image, url, article, websiteConfig }: SeoProps) => {
  const { metadata:{ titleTemplate, twitterUsername } } = websiteConfig

  return (
    <Helmet titleTemplate={titleTemplate}>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta name="image" content={image} />
      <meta name="twitter:card" content="summary_large_image" />

      {image && <meta property="og:image" content={image} />}
      {image && <meta name="twitter:image" content={image} />}
      {url && <meta property="og:url" content={url} />}
      {article && (
        <meta property="og:type" content="article" />
      )}
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
    </Helmet>
  )
}

export default Seo
