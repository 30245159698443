import * as React from 'react'
import { graphql } from 'gatsby'

import { withLayout } from 'components/Layout'

import Post, { PostPageProps } from 'components/pages/blog/Post/Post'

import 'stylesheets/all.scss'

/**
 * Mediator between a real page component and Gatsby conventions:
 * decoupled for the tests sake
 * (currently graphql is not available in Storybook)
 */
const blogDetail = (props: PostPageProps) => <Post {...props}/>

export const query = graphql`
  query blogPostBySlug(
      $filePath: String,
      $nextPath: String,
      $prevPath: String
    ) {
    # Site metadata
    site {
      siteMetadata {
        title
        description
        logoTitle
        logoSecondaryTitle
        logoImageAlt
      }
    }
    # Detail content
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      fields {
        slug
        filePath
      }
      frontmatter {
        title
        dateRaw: date
        date(formatString: "MMMM DD, YYYY")
        description
        keywords
        author
        excerpt
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      html
    }
    # Prev
    previous: markdownRemark(fields: { filePath: { eq: $prevPath } }) {
      frontmatter {
        title
        slug
      }
    }
    # Next
    next: markdownRemark(fields: { filePath: { eq: $nextPath } }) {
      frontmatter {
        title
        slug
      }
    }
  }
  `

export default withLayout(blogDetail)
